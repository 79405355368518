.dashboard-inner-content {
  overflow-y: scroll;
}

.dashboard {
  gap: 1rem;
}

#wrapper {
  display: flex;
}

.dashboard .card {
  height: 100%;
}
