.is-signed-in {
  background-color: $red;
  --bs-table-accent-bg: $red !important;
  color: $white !important;
}

.sign-in-table-wrapper {
  margin-left: 0.25rem;
  margin-right: 0.25rem;

  @include media-breakpoint-up(sm) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
